* {
  box-sizing: inherit;
}

*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  touch-action: manipulation;
  -ms-touch-action: manipulation;
  font-size: 62.5%;
  font-family: "Inter", sans-serif;
}

body {
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

img,
embed,
object,
audio,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
  text-align: left;
}

ul {
  list-style: none;
}

button,
input,
select,
textarea {
  margin: 0;
}
